@use './normalize.scss';
@use './resets.scss';
@use './buttons.scss';
@use './chart.scss';
@use './infoBar.scss';
@use './switch.scss';
@use './editableLabel.scss';

@import './constants.scss';

$nav-bar-height: 40px;

body {
  @extend .text--medium;
  color: $color-font;
  background-color: $color-foreground;
  overflow-y: hidden;
}

input {
  font-family: "Noto Sans JP", sans-serif;
  color: $color-font;
  width: 100%;
  border-bottom: solid 1px $color-border;
  padding-left: 0;
}

a {
  color: $color-font;
  &:hover {
    color: $color-font-bright;
  }
}

ul {
  margin-block-start: $s;
  padding-inline-start: 2*$s;
}

li {
  margin-bottom: 8px;
}

.content {
  display: flex;
  height: calc(100vh - #{$nav-bar-height});
  overflow-y: visible;
  margin-top: $nav-bar-height;
  background-color: $color-background;

  &.content--modal-closed {
    overflow-y: auto;

    &.content--sidebar--open {
      overflow-y: visible;

      @media screen and (min-width: $desktop-width) {
        overflow-y: auto;
      }
    }
  }
}

.nav-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: $color-foreground;
  padding: 0 2*$s;
  z-index: $z-nav-bar;
  height: $nav-bar-height;
}

.nav-bar__side-bar-toggle.icon-button img {
  height: 22px;
}

.nav-bar__side-bar__title {
  margin-left: $s;
}

.nav-bar__title {
  @extend .label--medium;
}

.content__main {
  padding: 2*$s;
}

.content__sidebar {
  background-color: $color-foreground;
  height: calc(100vh - #{$nav-bar-height});
  width: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  position: fixed;
  z-index: $z-side-bar;

  @media screen and (min-width: $desktop-width) {
    position: sticky;
    top: 0;
  }

  .sidebar-title-bar .title {
    display: none;
    opacity: 0;
  }
  .content__sidebar__content {
    opacity: 0;
  }

  .content--sidebar-open & {
    width: 100%;
    min-width: 300px;
    max-width: 90%;
    overflow-y: auto;
    overflow-x: auto;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.2));

    .content__sidebar__content, .sidebar-title-bar .title {
      display: initial;
      opacity: 1;
    }

    @media screen and (min-width: $desktop-width) {
      width: 30vw;
      min-width: 430px;
      max-width: 600px;
      filter: none;
    }
  }
}

.content__sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-foreground;
  opacity: 0.6;
  display: none;

  @media screen and (min-width: $desktop-width) {
    display: none;
  }

  .content--sidebar-open & {
    display: block;
    @media screen and (min-width: $desktop-width) {
        display: none;
    }
  }
}

.content__sidebar__content {
}

.content__sidebar__content__wrapper {
  padding: 0 2*$s 2*$s 2*$s;
}

.sidebar__title {
  @extend .label--medium;
}

.title {
  text-transform: uppercase;
  font-weight: 300;
  margin: 0 0 2*$s 0;
}

.row-container__title-bar {
  display: flex;
  align-items: center;

  .row-container__title-bar__title {
    margin: 0 2*$s 0 0;
  }
}

.row-container__title-bar__buttons {
  display: flex;
  align-items: center;

  & > * {
    margin-left: $s;
  }

  & > *:first-child {
    margin-left: 0;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: $s 0 $s -2*$s;
  padding: $s 0;
  & > * {
    margin-bottom: $s;
    margin-left: 2*$s;
  }

  @media screen and (min-width: $desktop-width) {
    justify-content: flex-start;
  }
}

.box {
  display: flex;
  flex-direction: column;
  border-radius: $box-border-radius;
  background-color: $color-foreground;
  text-align: center;
  min-width: 100px;
}

.box--small {
  width: 100px;
}

.box--medium {
  width: 140px;
}

.box--large {
  width: 220px;
}

.box--wide {
  min-width: 300px;
  max-width: $desktop-width - 100px;
  width: 100%;

  @media screen and (min-width: $desktop-width) {
    min-width: 500px;
    max-width: 1000px;
    width: 100%;
  }
}

.box__section {
  flex-grow: 1;
  padding: $s;
  border-bottom: solid 1px $color-gray-4;

  &:last-child {
    margin-bottom: 0;
    border-bottom-left-radius: $box-border-radius;
    border-bottom-right-radius: $box-border-radius;
  }

  .title {
    margin-bottom: $s;
  }
}

.box__section--title {
  flex-grow: 0;
}

.box__section--clickable {
  cursor: pointer;

  &:hover {
    background-color: $color-gray-4;

    .label {
      color: $color-font-bright;
    }
  }

  &:active {
    background-color: $color-gray-5;
  }

  .label {
    cursor: pointer;
  }
}

.border {
  border-top: solid 1px #111;
  margin: $s 0;
}

.upper {
  text-transform: uppercase;
}

table {
  border: solid 1px #222;
  border-collapse: separate;
  border-spacing: $s;

  &.table--small {
    th {
      @extend .label--tiny--faded;
    }

    td {
      @extend .text--tiny--faded;
    }
    @media screen and (min-width: $desktop-width) {
      font-size: 12px;
      line-height: 14px;
      th {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

th {
  @extend .label--small--faded;
  font-weight: 700;
}

td {
  padding: 0;
  margin: 0;
  margin-right: 2*$s;
}

.table-cell:last-child {
  padding-right: 0;
}

.nowrap {
  white-space: nowrap;
}

.tag-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.tag-dropdown {
  cursor: pointer;
  padding: 4px;

  &:hover {
    background-color: $color-foreground;

    .tag-dropdown__dropdown {
      visibility: visible;
    }
  }

  &.tag-dropdown--add {
    position: absolute;
    right: -4px;
  }
}


.tag-dropdown__dropdown {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: $color-foreground;
  padding: 2px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: $z-overlay;

  &:hover {
    visibility: visible;
  }
}

.tag-dropdown--add {
  visibility: hidden;

  .box:hover & {
    visibility: visible;
  }
}

.tag {
  @extend .label--small--heavy;
  color: $color-green;
  min-width: 18px;
  padding: 2px;

  .tag-dropdown__dropdown & {
    color: $color-font;
  }
}

.tag--selector {
  margin-top: 4px;

  &:hover {
    background-color: $color-foreground-faded;
    color: $color-green;
  }

  &:active {
    background-color: $color-foreground;
  }

  &.tag--active, &.tag--active:hover {
    color: $color-green;
    background-color: inherit;
  }
}

.toggle-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: -8px;
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px $color-gray-1;
  border-radius: 14px;
  padding: 2px 6px;
  min-width: 30px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  @extend .label--tiny--heavy;
  color: $color-gray-1;
  white-space: nowrap;
  vertical-align: middle;

  &:hover, &.toggle--active:hover {
    border-color: $color-white-1;
    color: $color-white-1;
  }

  & img {
    display: none;
    height: 13px;
    margin-top: -2px;
    margin-right: 4px;
  }

  &.toggle--active {
    color: $color-white-1;
    border-color: $color-green;
    img {
      display: initial;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.title-bar {
  display: flex;
  justify-content: space-between;
  position: relative;

  .title-bar__label {
    flex-grow: 1;
    text-align: left;
  }
  

  > .icon-button {
    flex-grow: 0;
  }
}

.exercise-table {
  border-spacing: 4px;
}

.chip {
  @extend .label--very-tiny--dark;
  border: solid 1px $color-border;
  padding: 2px 4px;
  white-space: nowrap;
}

.exercise-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $s;

  &--button {
    @extend .exercise-entry;
    cursor: pointer;

    &:hover {
      color: $color-font-bright;
      background-color: $color-gray-4;

      .icon-button {
        opacity: 1;
      }
    }

    &:active {
      color: initial;
      background-color: initial;
    }

    & .exercise-entry__label {
      @extend .label--small--faded;
      flex-grow: 0;
    }
  }
  &--button--center {
    @extend .exercise-entry--button;
    justify-content: center;
  }
}

.exercise-entry__btn {
  margin-right: $s;
}

.exercise-entry__label {
  @extend .label--small;
  flex-grow: 1;
  text-align: center;
}

.exercise-entry__tag {
  align-self: center;
}

.modal-wrapper {
  height: 100%;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  background-color: $color-foreground;
  overflow-y: hidden;
  filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.1));

  .modal-wrapper--closed & {
    display: none;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-foreground;
  opacity: 0.6;

  .modal-wrapper--closed & {
    display: none;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  max-height: 100vh;
  height: 800px;
}

.modal-body {
  overflow-y: auto;
  padding: 0 2*$s 2*$s 2*$s;
  margin-bottom: 2*$s;
}

.modal-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2*$s;
}

.modal-title-bar__left {
  display: flex;
  align-items: center;
}

.modal-title {
  @extend .label--medium--heavy;
  margin-right: 2*$s;
}

.add-exercise-row {
  padding: 2*$s;
  cursor: pointer;
  @extend .label--medium;

  &:hover {
    color: $color-font-bright;
    background-color: $color-gray-4;
  }
}

.muscle-list {
  display: table;
}

.muscle-list__item {
  display: table-row;
}

.muscle-list__cell {
  display: table-cell;
}

.muscle-list__item__label {
}

.form-label {
  @extend .label--medium;
  margin-top: 2*$s;
  margin-bottom: $s;

  &:first-child {
    margin-top: 0;
  }
}

.form-input {
  display: flex;
  align-items: center;
  margin-bottom: $s;
}

.form-input__label {
  @extend .label--medium;
  margin-right: 2*$s;
}

.rep-range-inputs {
  display: flex;
  @extend .label--small;
}

.rep-range-input {
  margin-right: 2*$s;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }
}

.rep-range-input__inputs {
  display: flex;

  input {
    width: 16px;
    text-align: center;
  }
}
