@import './constants.scss';

button {
  padding: $s;
  background-color: $color-foreground;
  cursor: pointer;
  font-weight: 500;
  border-radius: $box-border-radius;
  user-select: none;

  &:hover {
    background-color: $color-foreground-faded;
  }

  &:active {
    background-color: $color-foreground;
  }

  &.btn--disabled, &.btn--disabled:hover, &.btn--disabled:active {
    cursor: initial;
    color: $color-font-faded;
    background-color: $color-foreground;
  }
}

.button--light {
  @extend .label--small--light;
  padding: 4px;
  cursor: pointer;
  font-weight: 500;
  border-radius: $box-border-radius;
  user-select: none;

  &:hover {
    background-color: $color-foreground-faded;
  }

  &:active {
    background-color: none;
  }
}

.icon-button {
  @extend .label--medium;
  cursor: pointer;
  opacity: 0.5;
  display: flex;
  align-items: center;
  user-select: none;

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 0.5;
  }

  img {
    height: 14px;
  }
}


.btn-add {
  height: 20px;
}

.btn-chevron--down {
  transform: rotate(-90deg);
}

.btn-chevron--up {
  transform: rotate(90deg);
}
